<template lang="">
  <div class="container">
    <div class="search wei-container flex-row">
      <div class="category flex1" v-if="categoryList">
        <div
          class="category_item pointer"
          v-for="(item, index) in categoryList"
          :class="{ active: activeCategory === item }"
          :key="index"
          @click="changeCatefoy(item)"
        >
          {{ item }}
        </div>
      </div>
      <div class="search-input">
        <el-input
          prefix-icon="el-icon-search"
          @keyup.enter.native="search"
          clearable=""
          :placeholder="job.search"
          v-model="params.title"
          @change="search"
        ></el-input>
      </div>
    </div>
    <div class="job_container wei-container ">
      <div class="job flex1" v-if="jobList">
        <div class="job_item" v-for="(item, index) in jobList" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="info">
            <span class="salary">{{ item.salary }}</span> |
            <span>{{ item.location }}</span> |
            <span>{{ item.category }}</span> |
            <span>{{
              item.createdTime && item.createdTime.split(" ")[0]
            }}</span>
          </div>
          <div class="section">
            <div class="section_name">{{ job.duty }}</div>
            <!-- <div v-html="item.duty"></div> -->
            <div class="section_value" v-if="item.duty">{{ item.duty }}</div>
          </div>
          <div class="section">
            <div class="section_name">{{ job.request }}</div>
            <!-- <div v-html="item.claim"></div> -->
            <div class="section_value" v-if="item.claim">{{ item.claim }}</div>
          </div>
        </div>
      </div>
     
    </div>
     <pagination
     class="pagination"
        v-show="total > 0"
        :total="total"
        :page.sync="params.pageNum"
        :limit.sync="params.pageSize"
        :autoScroll="false"
        @pagination="queryJobList"
      />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { queryJobList, queryJobCategory } from "@/api/product";
import Pagination from "@/components/Pagination/index";
export default {
  components: { Pagination },
  data() {
    return {
      job: {},
      categoryList: [],
      jobList: [],
      activeCategory: 0,
      total: 1,
      params: {
        pageNum: 1,
        pageSize: 10,
        title: "",
        category: "",
      },
    };
  },
  created() {
    this.job = this.$t("job");
    this.getQueryData();
  },
  mounted() {},
  methods: {
    getQueryData() {
      queryJobCategory().then((res) => {
        this.categoryList = res.data;
        this.activeCategory = res.data[0];
        this.params.category = this.activeCategory;
        this.queryJobList();
      });
    },
    queryJobList() {
      queryJobList(this.params).then((res) => {
        this.jobList = res.data.records;
        this.total = res.data.total;
      });
    },
    search() {
      this.params.page=1
      this.queryJobList()
    },
    changeCatefoy(item) {
      this.activeCategory = item;
      this.params = {
        ...this.params,
        pageNum: 1,
        pageSize: 10,
        category: item,
      };
      this.queryJobList();
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 80px 0 200px 0;
  text-align: left;
  .search {
    .category {
      .category_item {
        width: 150px;
        height: 50px;
        display: inline-block;
        line-height: 50px;
        background: #ffffff;
        text-align: center;
        font-size: 18px;
        margin-right: 40px;
        &.active {
          background: #41cfad;
          color: #fff;
        }
      }
    }
  }
  .job_container {
    text-align: left;
    
    .job_item {
      background: #fff;
      padding: 50px;
      margin-top: 40px;
      .title {
        font-size: 24px;
      }
      .info {
        margin-top: 20px;
        .salary {
          color: #ff6600;
        }
      }
      .section {
        margin-top: 38px;
        .section_name {
          font-size: 18px;
          margin-bottom: 18px;
        }
        .section_value {
          line-height: 1.5;
        }
      }
    }
  }
  .pagination{
    margin-top: 80px;
  }
}
</style>
<style lang="scss">
.search-input {
  .el-input {
    width: 300px;
    height: 50px;
    background: #eeeeee;
    .el-input__inner {
      height: 100%;
      line-height: 1;
      background: #eeeeee;
      border: 0;
      padding: 0 42px 0 42px ;
      font-size: 18px;
    }
    .el-input__icon {
      line-height: 1;
      width: 21px;
      height: 21px;
      font-size:21px;
      color: #262f3b;
      margin-top: 15px;
      margin-left:10px;
      margin-right: 10px;;
    }
  }
}
</style>
